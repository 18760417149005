import(/* webpackMode: "eager", webpackExports: ["Row","Col","Flex"] */ "__barrel_optimize__?names=Col,Flex,Row!=!/var/www/yakutya_front/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/var/www/yakutya_front/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/yakutya_front/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/yakutya_front/src/lib/AntdRegistry.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/styles/accessibility.css");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/common/base-container/styles/base-container.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/common/footer/footer.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\"]}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/MADE-Barista.woff\"}],\"variableName\":\"barista\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/yakutya_front/src/utils/provider.tsx");
